/* Global Styles ------------------------------------------------------------- */ 
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.block {
  display: block;
}
.center {
  text-align: center;
}
/* Home Styles ---------------------------------------------------------------- */
#app-container {
  background-color: #F0F0F0;
  height: 100%;
  width: 100%;
}
.home-loader-container {
  text-align: center;
  margin-top: 240px;
}
.home-loader-text {
  margin-bottom: 40px;
  font-size: 24px;
  color: grey;
}
.header-container {
  display: block;
  position: fixed;
  box-shadow: 0px 4px 4px -4px lightgrey;
  background-color: white;
  height: 200px;
  top: 0;
  z-index: 10px;
  width: 100%;
}
.logo-text-coronavirus {
  color: #216193;
  font-size: 11px;
  width: 90%;
  margin: 0 auto;
}
.ehn-logo-container {
  display: inline-block;
  width: 25%;
  text-align: center;
  top: 10px;
  position: relative;
}
.ehn-logo {
  height: 110px;
  width: 200px;
}
.keywords-input-container {
  display: inline-block;
  width: 50%;
  /* top: -20px;  */
  /* top: -65px; */
  /* top is -65 in production */
}
.keyword-input-container {
  display: inline-block;
  width: 75%;
  /* top: -20px;  */
  /* top: -65px; */
  /* top is -65 in production */
}
.group-input-container {
  display: inline-block;
  width:25%;
}
.location-input-container {
  display: inline-block; 
  width: 75%;
}
.range-select-container {
  display: inline-block;
  width: 25%;
}
.search-btn-toggle-filters-container {
  display: inline-block;
  text-align: center;
  width: 10%; 
  margin-left: 7%;
}
.toggle-filter-type {
  display: inline-block; 
  width: 180px;
  margin-left: 30px;
}
.radio-filter-text {
  font-size: 13px; 
  display: inline-block;
  width: 50%; 
  /* margin-right: 10px; */
}
.sections-container {
  position: absolute; 
  top: 210px;
  width: 98%; 
  margin-left: 1%;
}
.sections-container-on-top {
  position: absolute; 
  top: 0px;
  width: 98%; 
  margin-left: 1%;
}
.sections {
  display: block;
  width: 100%;
}
.filters-section {
  display: inline-block;
  width: 25%;
}
.results-section {
  display: inline-block;
  width: 50%;
}
.results-map-section {
  display: inline-block;
  width: 25%;
}
/* Filter Section Styles ----------------------------------------------------- */
.filters-view-container {
  height: 600px;
  padding-bottom: 300px;
  /* overflow-y: scroll; */
}
.filter-with-children-container {
  width: 100%; 
  margin-left: 0px; 
  padding-left: 0px;
}
.filter-name-text {
  display: inline-block;
  width: 75%;
  padding-left: 5%;
  font-size: 13px;
  height: 40px;
}
.filter-container {
  width: 100%; 
  margin-left: 0px; 
  padding-left: 0px;
  height: 40px;
}
.facility-filter-container {
  display: block; 
  width: 100%;
  height: 40px;
}

/* Results List Section Styles ------------------------------------------------ */
#filter-label-container {
  width: 100%;
  height: 100%; 
  margin-top: 20px;
}
.search-results-container {
    /* overflow-y: scroll; */
    height: 600px;
    padding-bottom: 240px;
}
.card-title{
  margin:0;
}
/* SearchResultCard Styles ----------------------------------------------------- */
.card-content-container {
  display: block; 
  width: 100%; 
  /* padding-top: 10px; */
}
.card-title-container {
  display: inline-block;
  width: 90%;
}
.card-label {
  display: inline-block;
  width: 10%;
  text-align: center;
}
.miles-away-text {
  color: silver;
  font-size: 14px; 
  margin-top: 5px;
}
.specialties-text {
  margin-top: 5px;
  display: inline-block;
  width: 80%;
  color: #216193;
  font-weight: bold;
}
.result-item-container:hover {
  cursor: pointer;
}
/* Splash Page Styles --------------------------------------------------------- */
.splash-background {
  display: inline-block;
  height: 100%;
  width: 75%; 
  margin-left: 25%;
}
.splash-panel-background {
  display: inline-block;
  /* background: #216193; */
  background: rgb(19, 19, 73);
  width: 25%;
  height: 100%;
  position: absolute;
}
/* Manipulated Styles --------------------------------------------------------- */
.ui.popup:before {
  display: none;
}
.should-hide-appbar {
  display: none;
  visibility: hidden;
  width: 0px;
}
.HeaderSection-paper-153 {
  box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12) !important;
  height: 40px;
}
.HeaderSection-root-152 {
  overflow: visible;
}
.MuiPaper-elevation2-93 {
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.171), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
  height: 40px;
}
.jss15 {
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
  height: 40px;
}
.jss207 {
  padding-top: 3px;
}
.grecaptcha-badge {
  visibility: hidden;
}
.grecaptcha-badge-visible {
  visibility: visible !important;
  /* has to be above 1300 */
  z-index: 1400; 
}
/* Splash Page ----------------------------------------------------------------- */
.get-care-you-need-text {
    color: #216193;
    font-size: 14px;
    padding-bottom: 20px;
    font-weight: bold;
}
.splash-care-card, .splash-care-card:hover {
  cursor: pointer;
  height: 100%;
}
.splash-background {
  display: block;
}
.splash-panel-background {
  display: block;
}
.powered-by-splash-text {
  color: #767676;
  font-size: 24px;
  margin: 0;
  font-weight: bold;
  font-style: italic;
}
.search-for-splash-text {
  color: #767676;
  font-size: 12px;
  padding-bottom: 5px;
}
.search-for-splash-text-bold {
  color: #767676;
  font-size: 12px;
  padding-bottom: 5px;
  font-weight: bold;
}
.search-for-splash-text-coronavirus {
color: #216193;
font-size: 12px;
}
.splash-btn-title {
    width: 70%;
    height: 100%;
    color: white;
    font-size: auto;
    padding-left: 5%;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
}
.splash-btn-desc {
    color: white;
    font-size: 12px;
    padding: 5%;
    font-style: italic;
    height: 100px;
    overflow: hidden;
    width: 90%;
    white-space: wrap;
    text-overflow: ellipsis;
}
@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  60% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
  60% {
    -webkit-transform: translateX(-15px);
            transform: translateX(-15px);
  }
}
@-webkit-keyframes bounceDown {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounceDown {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
.fa-arrow-right {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
  float: right;
}
.fa-chevron-down {
  -webkit-animation: bounceDown 2s infinite;
  animation: bounceDown 2s infinite;
text-align:center;
  display:block;
}

/* Media Queries --------------------------------------------------------------- */
/* NOTE: using a combo of media queries and js */
@media all and (max-width: 1100px) {
  .filters-section {
    display: none;
    width: 0%;
  }
  .results-section {
    display: inline-block;
    width: 100%;
  }
  .results-map-section {
    display: none;
    width: 0%;
  }
  .header-container {
    display: none;
    visibility: hidden;
  }
  .inputs-container {
    display: none;
    visibility: hidden;
  }
  .sections-container {
    position: absolute; 
    top: 80px;
  }
  .should-hide-appbar {
    display: block;
    visibility: visible;
    width: 100%
  }
  .ehn-logo-container {
  }
  .ehn-logo {
  }
  .keywords-input-container {
  }
  .location-input-container {
  }
  .range-select-container {
  }
  .search-btn-toggle-filters-container {
  }
  .splash-background {
    display: none;
  }
  .splash-panel-background {
    display: none;
  }
  .splash-btn-title {
    width: 70%;
    height: 100%;
    color:  rgb(19, 19, 73);
    font-size: auto;
    padding-left: 5%;
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
  }
  .splash-btn-desc {
    color: rgb(19, 19, 73);
    font-size: 12px;
    padding: 5%;
    font-style: italic;
    height: 90px;
    overflow: hidden;
    width: 90%;
    white-space: wrap;
    text-overflow: ellipsis;
  }
}
